/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Image, Text, Subtitle } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--style3 --full" anim={""} name={"einleitung"} animS={"3"} style={{"backgroundColor":"var(--color-custom-2)"}} border={""} fullscreen={true} css={css`
      background-position: 55% 6%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Title className="title-box fs--102 w--900 title-box--invert" style={{"maxWidth":787}} content={"Flyers, cards, catalogs, roll-ups and all print"}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--24 mt--30" content={"View portfolio"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-4qhsb1 --style2 --full pb--60 pt--60" name={"dienstleistungen"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes={"100vw"} style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"DTP Design"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"ab 199€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes={"100vw"} style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Visual Identity"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"ab 199€"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes={"100vw"} style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Print"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"ab 199€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"information"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={""} animS={"3"} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box lh--14" content={"With over 20 years of experience, I provide comprehensive graphic design assisance for your project, products, and services."}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"uber-mich"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":453}}>
              
              <Title className="title-box fs--62" content={"O mně"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":650}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape4" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/168/img-3_s=350x_.jpg"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/168/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-3_s=2000x_.jpg 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"referenzen"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--white);\">Reference</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--25" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape4" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape4" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/168/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-2_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="--shape4" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/i/template/168/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/168/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/168/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/168/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/168/img-1_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Button className="btn-box btn-box--shape4 fs--22 mt--08" content={"View portfolio"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--30" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--50 pt--50 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Title className="title-box fs--62" content={"Schreiben Sie uns"}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--22 mt--16" content={"Kontaktieren"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"partei"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"Ivan Kräuslich"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}